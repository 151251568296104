import { styled } from "@/components/content/stitches";

export const NoRoute = () => (
  <Container>
    <h1>Not found</h1>
    <p>We couldn't find what you were looking for</p>
  </Container>
);

const Container = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - var(--size-20))",
  justifyContent: "center",
  gap: "var(--size-2)",
  textAlign: "center",
  width: "100%",

  "h1, p": {
    margin: 0,
  },
});
