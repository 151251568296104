import { type RequestMeta } from "@koala/sdk";
import { getPopularProducts } from "@koala/sdk/v4";
import { Hydrate, QueryClient } from "@tanstack/react-query";
import {
  type InferGetServerSidePropsType,
  type GetServerSideProps,
} from "next";
import { initUrqlClient, type SSRData, withUrqlClient } from "next-urql";
import { ssrExchange, useQuery } from "urql";
import Layout from "@/components/app/layout/index";
import { Page as ContentPage } from "@/components/content/page";
import { NoRoute } from "@/components/no-route/no-route";
import { ROUTES } from "@/constants/routes";
import { getConfigOverride } from "@/features/configs/configs";
import { createHttpClient } from "@/services/client";
import { contentSetupAPIRequests } from "@/services/content.service";
import { getFooterTemplate } from "@/services/footer.service";
import { type ServerQuery, queryOnServer } from "@/services/ssr.service";
import { PageQueryDocument } from "@/types/graphql-schema";
import { getOrigin, getRequestOrigin } from "@/utils";

function Page({
  slug = "",
  serverState,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const [{ data }] = useQuery({
    query: PageQueryDocument,
    variables: { slug },
  });

  const hasPages = Boolean(data?.pages?.length);

  if (!hasPages) {
    return (
      <Hydrate state={serverState}>
        <Layout>
          <NoRoute />
        </Layout>
      </Hydrate>
    );
  }

  return (
    <Hydrate state={serverState}>
      <ContentPage
        id={data?.pages?.[0].id ?? ""}
        seoTitle={data?.pages?.[0].seoTitle ?? ""}
        seoDescription={data?.pages?.[0].seoDescription ?? ""}
        seoImage={data?.pages?.[0].seoImage ?? ""}
        content={data?.pages?.[0].content}
      />
    </Hydrate>
  );
}

interface PageProps {
  slug?: string;
  serverState?: unknown;
  urqlState?: SSRData;
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  req,
  params,
}) => {
  if (!req.headers.host) {
    throw new Error("Missing request origin");
  }

  const queryClient = new QueryClient();
  const meta = getRequestOrigin(req.headers.host);
  const ssrCache = ssrExchange({ isClient: false });
  const session = contentSetupAPIRequests(ssrCache, meta);
  const client = initUrqlClient(session, false);
  const origin = getOrigin(req.headers.host);

  /**
   * Even though this route only catches urls that include an id (ie: `/[id]`)
   * due to how Next handles route logic, `id` can be
   *  - undefined (no id)
   *  - a string (`/[id]`)
   *  - an array of strings (`/[id]/[second-id]/[etc]`)
   *
   * 1. First, we ensure an undefined id is coerced as an empty string.
   * 2. Next, we check if id is a multi-part url with additional `/` (this will be returned as an array)
   * 3. Finally, we coerce string arrays as a regular string by joining them with `/` (ie: `/about/us`)
   *
   * This is all to ensure we pass a valid string into the graphql query
   *
   */
  const id = params?.id ?? "";
  const slug = Array.isArray(id) ? id.join("/") : id;

  if (client) {
    const result = await client.query(PageQueryDocument, { slug }).toPromise();

    /**
     * This redirect handles a couple conditions:
     *
     * - If no matching Commerce+ pages are found, then we should redirect to
     * the root. (`/`) A common case for this is an  Olo redirect. Olo store
     * locations are structured as `/menu/[location-id]` which we want to
     * redirect back to the store locator. (`/` for non-Commerce+ brands)
     * The snag is that Commerce+ brands can create pages with the slug `/menu`
     * so we need to ensure that no Commerce+ page matches before redirecting.
     *
     * - If a Commerce+ page is found, but it's marked as the homepage in the
     * CMS, then we should redirect to the root. (`/`). The page contents will
     * continue to display, it's just that the URL will be correct.
     */
    if (
      result.data?.pages?.length === 0 ||
      Boolean(result.data?.pages?.[0]?.homepage)
    ) {
      return { redirect: { permanent: false, destination: ROUTES.HOMEPAGE } };
    }
  }

  const { flagship_store_location } = await getConfigOverride(
    "menu",
    queryClient,
    origin
  );

  let serverQueries: ServerQuery[] = [
    {
      queryKey: ["site-footer"],
      queryFn: () => getFooterTemplate(origin),
    },
  ];

  if (flagship_store_location) {
    serverQueries = serverQueries.concat([
      {
        queryKey: ["popular-items", { id: flagship_store_location }],
        queryFn: () =>
          getPopularProducts(
            { locationId: flagship_store_location, maxItems: 15 },
            { client: createHttpClient({ origin }) }
          ),
      },
    ]);
  }

  const serverState = await queryOnServer(serverQueries, queryClient);

  return {
    props: {
      slug,
      urqlState: ssrCache.extractData(),
      serverState,
    },
  };
};

export default withUrqlClient(
  (ssrExchange, ctx) => {
    let meta: RequestMeta | undefined = undefined;
    if (typeof window !== "undefined") {
      meta = getRequestOrigin(window.location.host);
    } else if (ctx?.req?.headers.host) {
      meta = getRequestOrigin(ctx.req.headers.host);
    }
    return contentSetupAPIRequests(ssrExchange, meta);
  },
  { neverSuspend: true }
)(Page);
